var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" }
      }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("header-search", { staticClass: "right-menu-item" }),
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect"
                }),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect"
                }),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: _vm.$t("navbar.size"),
                      effect: "dark",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("size-select", {
                      staticClass: "right-menu-item hover-effect"
                    })
                  ],
                  1
                ),
                _c("lang-select", {
                  staticClass: "right-menu-item hover-effect"
                })
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar + "?imageView2/1/w/80/h/80" }
                }),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/profile/" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(" " + _vm._s(_vm.$t("navbar.profile")) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(" " + _vm._s(_vm.$t("navbar.dashboard")) + " ")
                      ])
                    ],
                    1
                  ),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [_vm._v(_vm._s(_vm.$t("navbar.logOut")))]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }