/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'clipboard': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M54.9 118.9h64V73H89c-1.9 0-3.5-.6-4.8-2-1.3-1.3-2-3-2-4.8V36.6H54.9v82.3zM73 16v-4.6a2.2 2.2 0 0 0-.6-1.6 2.2 2.2 0 0 0-1.6-.7H20.6c-.7 0-1.2.3-1.6.7a2.2 2.2 0 0 0-.7 1.6V16a2.2 2.2 0 0 0 .7 1.6c.4.5 1 .7 1.6.7h50.3c.6 0 1.1-.2 1.6-.7.4-.4.6-1 .6-1.6zm18.3 48h21.4L91.4 42.6V64zm36.6 9.1v48c0 2-.7 3.6-2 4.9-1.3 1.3-3 2-4.9 2H52.6c-2 0-3.6-.7-4.9-2-1.3-1.3-2-3-2-4.9v-11.4H7c-2 0-3.6-.7-4.9-2-1.3-1.3-2-3-2-4.8v-96C0 4.9.7 3.3 2 2 3.3.7 5 0 6.9 0h77.7c1.9 0 3.5.7 4.8 2 1.4 1.3 2 3 2 4.9v23.4c1 .6 1.9 1.3 2.6 2l29.1 29.1c1.4 1.4 2.5 3.2 3.5 5.5s1.4 4.4 1.4 6.3z"/>'
  }
});