import "core-js/modules/es.object.to-string";
import Layout from '@/layout/index.vue';
var componentsRouter = {
  path: '/b2b',
  component: Layout,
  redirect: 'noRedirect',
  name: 'B2B',
  meta: {
    title: 'b2b.menu',
    icon: 'excel',
    roles: ['B2B_LICENSE']
  },
  children: [{
    path: 'approver',
    component: function component() {
      return import(
      /* webpackChunkName: "b2b" */
      '@/views/b2b/license/approver.vue');
    },
    name: 'Approver',
    meta: {
      title: 'b2b.approver'
    }
  }, {
    path: 'token',
    component: function component() {
      return import(
      /* webpackChunkName: "b2b" */
      '@/views/b2b/license/token.vue');
    },
    name: 'Token',
    meta: {
      title: 'b2b.token'
    }
  }, {
    path: 'license',
    component: function component() {
      return import(
      /* webpackChunkName: "b2b" */
      '@/views/b2b/license/license.vue');
    },
    name: 'License',
    meta: {
      title: 'b2b.license'
    }
  }, {
    path: 'history',
    component: function component() {
      return import(
      /* webpackChunkName: "b2b" */
      '@/views/b2b/license/history.vue');
    },
    name: 'History',
    meta: {
      title: 'b2b.history'
    }
  }]
};
export default componentsRouter;