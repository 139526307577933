import "core-js/modules/es.object.to-string";
import Layout from '@/layout/index.vue';
var componentsRouter = {
  path: '/contents',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Contents',
  meta: {
    title: 'contents',
    icon: 'edit',
    roles: ['CONTENT_ADMIN']
  },
  children: [{
    path: 'mission',
    component: function component() {
      return import(
      /* webpackChunkName: "reading" */
      '@/views/contents/mission/index.vue');
    },
    name: 'Mission',
    meta: {
      title: 'mission'
    }
  }, {
    path: 'setting',
    component: function component() {
      return import(
      /* webpackChunkName: "mission" */
      '@/views/contents/setting/index.vue');
    },
    name: 'Setting',
    meta: {
      title: 'setting'
    }
  }, {
    path: 'videoArchive',
    component: function component() {
      return import(
      /* webpackChunkName: "videoArchive" */
      '@/views/contents/videoArchive/index.vue');
    },
    name: 'VideoArchive',
    meta: {
      title: 'videoArchive'
    }
  }]
};
export default componentsRouter;