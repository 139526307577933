export default {
  route: {
    organization: 'Organization',
    organizationList: 'Organization List',
    manageOwner: 'Manage Owner',
    dashboard: 'Dashboard',
    contents: 'Contents',
    setting: 'Mission',
    mission: 'Contents',
    videoArchive: 'Video Archive',
    b2b: {
      menu: 'B2B',
      approver: 'Approver',
      token: 'Token',
      license: 'License',
      history: 'History'
    },
    profile: 'Profile',
    coupon: 'Coupon'
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    profile: 'Profile',
    size: 'Global Size'
  },
  login: {
    title: 'Connect Admin',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    google: 'Connect with Google',
    googleTips: 'Login with Google'
  },
  videoArchive: {
    title: 'Title',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    id: 'ID',
    category: 'Category',
    videoUrl: 'Video  Url',
    imageUrl: 'Image Url',
    createdAt: 'Date',
    actions: 'Actions',
    edit: 'Edit',
    delete: 'Delete',
    description: 'Description',
    tags: 'Tags',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  mission: {
    title: 'Title',
    share: 'Share',
    handArmFunction: 'Level',
    day: 'Day',
    image: 'Image',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    reading: 'Reading',
    quiz: 'Quiz',
    id: 'ID',
    createdAt: 'Date',
    actions: 'Actions',
    edit: 'Edit',
    detail: 'Detail',
    delete: 'Delete',
    type: 'Type',
    description: 'Description',
    meta: 'Meta',
    links: 'Links',
    tags: 'Tags',
    free: 'Free',
    shareUrl: 'ShareUrl',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  reading: {
    actions: 'Actions',
    edit: 'Edit',
    detail: 'Detail',
    add: 'Add',
    delete: 'Delete',
    saveorder: 'Save Order'
  },
  quiz: {
    actions: 'Actions',
    edit: 'Edit',
    detail: 'Detail',
    add: 'Add',
    delete: 'Delete',
    saveOrder: 'Save Order'
  },
  setting: {
    edit: 'Edit',
    day: 'Day',
    cancel: 'Cancel',
    save: 'Save',
    title: 'Title',
    search: 'Search'
  },
  organization: {
    add: 'Add',
    cancel: 'Cancel',
    confirm: 'Confirm',
    close: 'Close',
    save: 'Save',
    toOwner: 'To Main Owner',
    toNormal: 'To Normal',
    search: 'Search',
    manageOwner: {
      title: 'Manage Owner'
    },
    table: {
      name: 'Name',
      type: 'Type',
      clinic: 'Clinic Code',
      useYn: 'Use Y/N',
      email: 'Email',
      age: 'Age',
      gender: 'Gender',
      roleType: 'Role Type',
      organizationName: 'Organization Name'
    },
    create: {
      title: 'Add Organization'
    },
    image: 'Image'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    showTagsView: 'Open Tags-View',
    showSidebarLogo: 'Sidebar Logo',
    fixedHeader: 'Fixed Header',
    sidebarTextTheme: 'Sidebar Text Theme'
  },
  license: {
    approver: {
      email: 'E-Mail',
      search: 'Search',
      add: 'Add',
      id: 'Id',
      services: 'Services',
      active: 'Active',
      activate: 'Activate',
      inactivate: 'Inactivate',
      createdTime: 'Created',
      actions: 'Actions',
      summary: 'Summary',
      edit: 'Edit',
      close: 'Close',
      cancel: 'Cancel',
      confirm: 'Confirm',
      delete: 'Delete',
      unlimited: 'Unlimited',
      clinic: {
        appAllowed: 'App Allowed',
        smartGlove: 'Smart Glove',
        smartKids: 'Smart Kids',
        smartBoard: 'Smart Board',
        smartBalance: 'Smart Balance',
        smartTable: 'Smart Table'
      },
      comcog: {
        appCount: 'App Allowed',
        serviceAllowed: 'Service Allowed'
      },
      comcogPublic: {
        appCount: 'App Allowed',
        serviceAllowed: 'Service Allowed'
      },
      comcogAdmin: {
        appCount: 'App Allowed',
        serviceAllowed: 'Service Allowed'
      }
    },
    license: {
      activate: 'Activate',
      inactivate: 'Inactivate',
      deviceVendor: 'Vendor',
      deviceModel: 'Model',
      deviceSerialNumber: 'Serial',
      appId: 'App Id',
      id: 'id',
      info: 'Info'
    },
    history: {
      info: 'Info',
      search: 'Search',
      id: 'Id',
      licenseId: 'License Id',
      approverId: 'Approver Id',
      createdTime: 'Created'
    },
    token: {
      email: 'E-Mail',
      id: 'Id',
      appId: 'App Id',
      key: 'Token',
      search: 'Search',
      device: 'Device',
      expiredTime: 'Expired',
      createdTime: 'Created'
    }
  },
  coupon: {
    listTitle: 'Coupon List',
    list: {
      name: 'name',
      usePeriod: 'use period',
      benefitPeriod: 'benefit period',
      benefitPeriodUnit: 'month',
      benefitType: 'benefit',
      stock: 'stock',
      couponType: 'type',
      type: {
        welcomeCoupon: 'WelcomeCoupon'
      },
      button: {
        add: 'Add'
      },
      modal: {
        addTitle: 'Coupon add',
        editTitle: 'Coupon update',
        save: {
          success: 'save complete',
          fail: 'save fail'
        },
        keys: {
          name: 'name',
          usePeriod: 'use period',
          benefitPeriod: 'benefit period',
          benefitPeriodUnit: 'week',
          benefitType: 'benefit',
          stock: 'stock',
          couponType: 'type',
          useYn: 'use',
          code: 'code',
          radioOption: {
            useYnY: 'use',
            useYnN: 'unused'
          },
          validationErrorMsg: {
            name: {
              required: 'Please input Activity name',
              overlap: 'The name is duplicated.'
            },
            rangePicker: {
              required: 'Please input Activity use period'
            },
            code: {
              required: 'Please input Activity code',
              overlap: 'The code is duplicated.'
            }
          },
          placeholder: {
            codeOnlyWelcome: 'You can only use the Welcome Coupon.'
          }
        },
        buttons: {
          cancel: 'cancel',
          save: 'save'
        }
      }
    },
    userTitle: 'coupon member',
    user: {
      tableEmpty: 'No users have registered coupons in the Neofect Connect app',
      keys: {
        email: 'ID',
        period: 'period',
        type: 'type',
        code: 'code'
      }
    }
  }
};