/* eslint-disable no-console */
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register("".concat(process.env.BASE_URL, "service-worker.js"), {
    ready: function ready() {
      console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
    },
    registered: function registered(registration) {
      console.log('Service worker has been registered.'); // Routinely check for app updates by testing for a new service worker.

      setInterval(function () {
        registration.update();
      }, 1000 * 60 * 60); // hourly checks
    },
    cached: function cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound: function updatefound() {
      console.log('New content is downloading.');
    },
    updated: function updated(registration) {
      console.log('New content is available; please refresh.'); // Add a custom event and dispatch it.
      // Used to display of a 'refresh' banner following a service worker update.
      // Set the event payload to the service worker registration object.

      document.dispatchEvent(new CustomEvent('swUpdated', {
        detail: registration
      }));
    },
    offline: function offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error: function error(_error) {
      console.error('Error during service worker registration:', _error);
    }
  });
}