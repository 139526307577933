/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'drag': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M73.1 29H64h29.7L64 0 34.4 29h20.5v27.1H27.2v18H55v27.1h18V74.1h27.4V56H73.1V29zM64 128l27.5-26.8H36.6l27.3 26.7zM0 65l27.2 27V38.2L0 65zm100.5-26.8V92L128 65l-27.5-26.8z"/>'
  }
});