import "core-js/modules/es.object.to-string";
import Layout from '@/layout/index.vue';
var organizationRouter = {
  path: '/organization',
  component: Layout,
  redirect: '/organization/list',
  meta: {
    title: 'organization',
    icon: 'tree',
    roles: ['CLINIC_ADMIN']
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/organization/list.vue');
    },
    name: 'Organizations',
    meta: {
      title: 'organization',
      icon: 'table'
    }
  }]
};
export default organizationRouter;