import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.keys";
import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getLanguage } from '@/utils/cookies'; // element-ui built-in lang

import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementKoLocale from 'element-ui/lib/locale/lang/ko'; // User defined lang

import enLocale from './en';
import koLocale from './ko';
Vue.use(VueI18n);
var messages = {
  en: _objectSpread({}, enLocale, {}, elementEnLocale),
  ko: _objectSpread({}, koLocale, {}, elementKoLocale)
};
export var getLocale = function getLocale() {
  var cookieLanguage = getLanguage();

  if (cookieLanguage) {
    return cookieLanguage;
  }

  var language = navigator.language.toLowerCase();
  var locales = Object.keys(messages);

  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];

    if (language.indexOf(locale) > -1) {
      return locale;
    }
  } // Default language is english


  return 'en';
};
var i18n = new VueI18n({
  locale: getLocale(),
  messages: messages
});
export default i18n;