import "core-js/modules/es.object.to-string";
import axios from 'axios';
import { Message } from 'element-ui';
import { UserModule } from '@/store/modules/user';
var service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000 // withCredentials: true // send cookies when cross-domain requests

}); // Request interceptors

service.interceptors.request.use(function (config) {
  // Add X-Access-Token header to every request, you can add other custom headers here
  if (UserModule.token && UserModule.token.access_token) {
    config.headers['Authorization'] = "Bearer ".concat(UserModule.token.access_token);
  }

  return config;
}, function (error) {
  Promise.reject(error);
}); // Response interceptors

service.interceptors.response.use(function (response) {
  var res = response.data; // FIXME : 아니 api reponse 래핑 규약도 없는데 이게 왜 있는거야!!!!

  if (res.code && res.code !== 20000 && res.message) {
    Message({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(new Error(res.message || 'Error'));
  } else if (response.data) {
    return response.data;
  } else {
    return response;
  }
}, function (error) {
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;