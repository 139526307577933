import Cookies from 'js-cookie'; // App

var sidebarStatusKey = 'sidebar_status';
export var getSidebarStatus = function getSidebarStatus() {
  return Cookies.get(sidebarStatusKey);
};
export var setSidebarStatus = function setSidebarStatus(sidebarStatus) {
  return Cookies.set(sidebarStatusKey, sidebarStatus);
};
var languageKey = 'language';
export var getLanguage = function getLanguage() {
  return Cookies.get(languageKey);
};
export var setLanguage = function setLanguage(language) {
  return Cookies.set(languageKey, language);
};
var sizeKey = 'size';
export var getSize = function getSize() {
  return Cookies.get(sizeKey);
};
export var setSize = function setSize(size) {
  return Cookies.set(sizeKey, size);
};