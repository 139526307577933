import "core-js/modules/es.object.to-string";
import Layout from '@/layout/index.vue';
var couponRouter = {
  path: '/coupon',
  component: Layout,
  redirect: '/coupon/index',
  meta: {
    title: 'coupon',
    icon: 'tree',
    roles: ['CONTENT_ADMIN']
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/coupon/index.vue');
    },
    name: 'coupon',
    meta: {
      title: 'coupon',
      icon: 'qq'
    }
  }]
};
export default couponRouter;