// User
var tokenKey = 'auth_token';
export var getToken = function getToken() {
  return JSON.parse(getTokenFromStorage() || '{}');
};
export var setToken = function setToken(token) {
  return setTokenInStorage(JSON.stringify(token));
};
export var removeToken = function removeToken() {
  return clearTokenInStorage();
};

function getTokenFromStorage() {
  return sessionStorage && sessionStorage.getItem(tokenKey);
}

function setTokenInStorage(token) {
  sessionStorage && token && sessionStorage.setItem(tokenKey, token);
}

function clearTokenInStorage() {
  sessionStorage && sessionStorage.removeItem(tokenKey);
}