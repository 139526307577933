import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.some";
import "core-js/modules/es.string.includes";
import { UserModule } from '@/store/modules/user';
export var permission = {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var roles = UserModule.roles;

    if (value && value instanceof Array && value.length > 0) {
      var permissionRoles = value;
      var hasPermission = roles.some(function (role) {
        return permissionRoles.includes(role);
      });

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("need roles! Like v-permission=\"['role_admin','editor']\"");
    }
  }
};