export default {
  route: {
    organization: 'Organization',
    organizationList: 'Organization List',
    manageOwner: 'Manage Owner',
    dashboard: 'Dashboard',
    contents: 'Contents',
    setting: 'Mission',
    mission: 'Contents',
    videoArchive: 'Video Archive',
    b2b: {
      menu: 'B2B',
      approver: 'Approver',
      token: 'Token',
      license: 'License',
      history: 'History'
    },
    profile: 'Profile',
    coupon: '쿠폰'
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    profile: 'Profile',
    size: 'Global Size'
  },
  login: {
    title: 'Connect Admin',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    google: 'Connect with Google',
    googleTips: 'Login with Google'
  },
  videoArchive: {
    title: 'Title',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    id: 'ID',
    category: 'Category',
    videoUrl: 'Video  Url',
    imageUrl: 'Image Url',
    createdAt: 'Date',
    actions: 'Actions',
    edit: 'Edit',
    delete: 'Delete',
    description: 'Description',
    tags: 'Tags',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  mission: {
    title: 'Title',
    share: 'Share',
    handArmFunction: 'Level',
    day: 'Day',
    image: 'Image',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    reading: 'Reading',
    quiz: 'Quiz',
    id: 'ID',
    createdAt: 'Date',
    actions: 'Actions',
    edit: 'Edit',
    detail: 'Detail',
    delete: 'Delete',
    type: 'Type',
    description: 'Description',
    meta: 'Meta',
    links: 'Links',
    tags: 'Tags',
    free: 'Free',
    shareUrl: 'ShareUrl',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  reading: {
    actions: 'Actions',
    edit: 'Edit',
    detail: 'Detail',
    add: 'Add',
    delete: 'Delete',
    saveorder: 'Save Order'
  },
  quiz: {
    actions: 'Actions',
    edit: 'Edit',
    detail: 'Detail',
    add: 'Add',
    delete: 'Delete',
    saveOrder: 'Save Order'
  },
  setting: {
    edit: 'Edit',
    day: 'Day',
    cancel: 'Cancel',
    save: 'Save',
    title: 'Title',
    search: 'Search'
  },
  organization: {
    add: 'Add',
    cancel: 'Cancel',
    confirm: 'Confirm',
    close: 'Close',
    save: 'Save',
    toOwner: 'To Main Owner',
    toNormal: 'To Normal',
    search: 'Search',
    manageOwner: {
      title: 'Manage Owner'
    },
    table: {
      name: 'Name',
      type: 'Type',
      clinic: 'Clinic Code',
      useYn: 'Use Y/N',
      email: 'Email',
      age: 'Age',
      gender: 'Gender',
      roleType: 'Role Type',
      organizationName: 'Organization Name'
    },
    create: {
      title: 'Add Organization'
    },
    image: 'Image'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    showTagsView: 'Open Tags-View',
    showSidebarLogo: 'Sidebar Logo',
    fixedHeader: 'Fixed Header',
    sidebarTextTheme: 'Sidebar Text Theme'
  },
  license: {
    approver: {
      email: 'E-Mail',
      search: 'Search',
      add: 'Add',
      id: 'Id',
      services: 'Services',
      active: 'Active',
      activate: 'Activate',
      inactivate: 'Inactivate',
      createdTime: 'Created',
      actions: 'Actions',
      summary: 'Summary',
      edit: 'Edit',
      close: 'Close',
      cancel: 'Cancel',
      confirm: 'Confirm',
      delete: 'Delete',
      unlimited: 'Unlimited',
      clinic: {
        appAllowed: 'App Allowed',
        smartGlove: 'Smart Glove',
        smartKids: 'Smart Kids',
        smartBoard: 'Smart Board',
        smartBalance: 'Smart Balance',
        smartTable: 'Smart Table'
      },
      comcog: {
        appCount: 'App Allowed',
        serviceAllowed: 'Service Allowed'
      },
      comcogPublic: {
        appCount: 'App Allowed',
        serviceAllowed: 'Service Allowed'
      },
      comcogAdmin: {
        appCount: 'App Allowed',
        serviceAllowed: 'Service Allowed'
      }
    },
    license: {
      activate: 'Activate',
      inactivate: 'Inactivate',
      deviceVendor: 'Vendor',
      deviceModel: 'Model',
      deviceSerialNumber: 'Serial',
      appId: 'App Id',
      id: 'id',
      info: 'Info'
    },
    history: {
      info: 'Info',
      search: 'Search',
      id: 'Id',
      licenseId: 'License Id',
      approverId: 'Approver Id',
      createdTime: 'Created'
    },
    token: {
      email: 'E-Mail',
      id: 'Id',
      appId: 'App Id',
      key: 'Token',
      search: 'Search',
      device: 'Device',
      expiredTime: 'Expired',
      createdTime: 'Created'
    }
  },
  coupon: {
    listTitle: '쿠폰목록',
    list: {
      name: '쿠폰명',
      usePeriod: '등록 유효기간',
      benefitPeriod: '혜택기간',
      benefitPeriodUnit: '주',
      benefitType: '혜택',
      stock: '수량',
      couponType: '속성',
      type: {
        welcomeCoupon: '웰컴쿠폰'
      },
      button: {
        add: '추가'
      },
      modal: {
        addTitle: '쿠폰 추가',
        editTitle: '쿠폰 수정',
        save: {
          success: '저장이 완료되었습니다.',
          fail: '저장이 실패되었습니다.'
        },
        keys: {
          name: '쿠폰명',
          usePeriod: '쿠폰기간',
          benefitPeriod: '혜택기간',
          benefitPeriodUnit: '개월',
          benefitType: '혜택',
          stock: '쿠폰수량',
          couponType: '속성',
          useYn: '쿠폰 활성화',
          code: '쿠폰코드',
          radioOption: {
            useYnY: '활성화',
            useYnN: '비활성화'
          },
          validationErrorMsg: {
            name: {
              required: '쿠폰명을 넣어주세요.',
              overlap: '쿠폰명이 중복됩니다.'
            },
            rangePicker: {
              required: '쿠폰기간을 넣어주세요.'
            },
            code: {
              required: '쿠폰코드를 넣어주세요.',
              overlap: '쿠폰코드가 중복됩니다.'
            }
          },
          placeholder: {
            codeOnlyWelcome: '웰컴쿠폰에서만 사용가능합니다.'
          }
        },
        buttons: {
          cancel: '취소',
          save: '저장'
        }
      }
    },
    userTitle: '쿠폰등록회원',
    user: {
      tableEmpty: '네오펙트 커넥트 앱에 쿠폰을 등록한 사용자가 없습니다',
      keys: {
        email: '회원아이디',
        period: '기간',
        type: '속성',
        code: '쿠폰코드'
      }
    }
  }
};