import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import "/app/node_modules/core-js/modules/es.array.iterator.js";
import "/app/node_modules/core-js/modules/es.promise.js";
import "/app/node_modules/core-js/modules/es.object.assign.js";
import "/app/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import 'normalize.css';
import ElementUI from 'element-ui';
import SvgIcon from 'vue-svgicon';
import '@/styles/element-variables.scss';
import '@/styles/index.scss';
import App from '@/App.vue';
import store from '@/store';
import { AppModule } from '@/store/modules/app';
import router from '@/router';
import _i18n from '@/lang';
import '@/icons/components';
import '@/permission';
import '@/utils/error-log';
import '@/pwa/register-service-worker';
import * as directives from '@/directives';
import * as filters from '@/filters';
Vue.use(ElementUI, {
  size: AppModule.size,
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
}); // Register global directives

Object.keys(directives).forEach(function (key) {
  Vue.directive(key, directives[key]);
}); // Register global filter functions

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
new Vue({
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');