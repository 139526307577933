import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import request from '@/utils/request';
export var getUsers = function getUsers(params) {
  return request({
    url: '/user/api/v1/users',
    method: 'get',
    params: params
  });
};
export var getUserInfo = function getUserInfo(data) {
  return request({
    url: '/user/api/v1/users/me?type=admin',
    method: 'get',
    data: data
  });
};
export var getUserByName = function getUserByName(username) {
  return request({
    url: "/user/api/v1/users/email/".concat(username),
    method: 'get'
  });
};
export var updateUser = function updateUser(username, data) {
  return request({
    url: "/user/api/v1/users/email/".concat(username),
    method: 'put',
    data: data
  });
};
export var deleteUser = function deleteUser(username) {
  return request({
    url: "/user/api/v1/users/email/".concat(username),
    method: 'delete'
  });
};
export var login = function login(data) {
  return request({
    url: '/uaa/oauth/token?' + Object.keys(data).map(function (key) {
      return key + '=' + data[key];
    }).join('&'),
    method: 'post'
  });
};
export var logout = function logout() {};
/*
  request({
    url: '/uaa/oauth/logout',
    method: 'post'
  })
*/

export var register = function register(data) {
  return request({
    url: '/user/api/v1/users/register',
    method: 'post',
    data: data
  });
};