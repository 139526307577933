import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import Router from 'vue-router';
/* Layout */

import Layout from '@/layout/index.vue';
/* Router modules */

import contentsRouter from './modules/contents';
import organizationRouter from './modules/organization';
import couponRouter from '@/router/modules/coupon';
import b2bRouter from './modules/b2b';
Vue.use(Router);
/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/

/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/

export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  meta: {
    hidden: true
  },
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import(
      /* webpackChunkName: "redirect" */
      '@/views/redirect/index.vue');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import(
    /* webpackChunkName: "login" */
    '@/views/login/index.vue');
  },
  meta: {
    hidden: true
  }
}, {
  path: '/auth-redirect',
  component: function component() {
    return import(
    /* webpackChunkName: "auth-redirect" */
    '@/views/login/auth-redirect.vue');
  },
  meta: {
    hidden: true
  }
}, {
  path: '/404',
  component: function component() {
    return import(
    /* webpackChunkName: "404" */
    '@/views/error-page/404.vue');
  },
  meta: {
    hidden: true
  }
}, {
  path: '/401',
  component: function component() {
    return import(
    /* webpackChunkName: "401" */
    '@/views/error-page/401.vue');
  },
  meta: {
    hidden: true
  }
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import(
      /* webpackChunkName: "dashboard" */
      '@/views/dashboard/index.vue');
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  meta: {
    hidden: true
  },
  children: [{
    path: 'index',
    component: function component() {
      return import(
      /* webpackChunkName: "profile" */
      '@/views/profile/index.vue');
    },
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/

export var asyncRoutes = [contentsRouter, organizationRouter, b2bRouter, couponRouter];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history',  // Disabled due to Github Pages doesn't support this, enable this if you need.
    scrollBehavior: function scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return {
          x: 0,
          y: 0
        };
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;